<template>
  <div class="box">
    <div class="pageMsgText" v-for="(item, index) in pageMsg" :key="index">
        <!-- 遍历数据渲染到页面 -->
                <div class="infoTitle">
                    <div class="infoMsg_left">{{item.title}}</div>
                    <div class="infoMsg_right">
                        <span>{{item.money}}/天</span>
                        <span>{{item.pay}}</span>
                    </div>
                </div>
                <div class="introduce">
                    <div class="introduceMsg text_left">
                        <span>工作地点：{{item.place}}</span>
                    </div>
                    <div class="introduceMsg text_center">
                        <span>招聘人数：{{item.people}} 人</span>
                    </div>
                    <div class="introduceMsg text_center">
                        <span>所属行业类型：{{item.workType}}</span>
                    </div>
                    <div class="introduceMsg text_right">
                        <span>更新日期：{{item.date}}</span>
                    </div>
                </div>
            </div>
  </div>
</template>

<script>
export default {
    name:'pagesMsg',
    data() {
    
           
        return {
            pageMsg :
         
         [
            {

title:'大学生志愿团招募',
money:'200',
pay:'日结',
place:'浙江省杭州市',
people:'200',
workType:'服务业',
date:'2022-09-06'
},
{
   title:'公益与救援',
money:'200',
pay:'日结',
place:'浙江省杭州市',
people:'200',
workType:'服务业',
date:'2022-09-06'
},
{
  title:'研究助理招募',
money:'6000',
pay:'月结',
place:'浙江省杭州市',
people:'5',
workType:'服务业',
date:'2022-09-06'
},
{
  title:'教授助理招募',
money:'6000',
pay:'月结',
place:'浙江省杭州市',
people:'2',
workType:'服务业',
date:'2022-09-06'
},
{
   title:'心理督导师助理招聘',
money:'6000',
pay:'月结',
place:'浙江省杭州市',
people:'3',
workType:'服务业',
date:'2022-09-06'
},
{
  title:'导师助理招聘',
money:'6000',
pay:'月结',
place:'浙江省杭州市',
people:'2',
workType:'服务业',
date:'2022-09-06'
},
         ]
        }
    },
}
</script>

<style scoped >
.box{
    width: 100%;
    height: 100%;
}
.pageMsgText{
    width: 100%;
    height: 150px;
    background-color: #efefef;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 20px 30px;
}
.infoTitle{
    width: 100%;
    height: 75px;
    line-height: 50px;
    display: flex;
}
.infoMsg_left{
    font-weight: 700;
    font-size: 22px;
    flex: 7;
    height: auto;
}
.infoMsg_right{
    flex: 1;
    display: flex;
}
.infoMsg_right span{
    flex: 1;
}
.introduce{
    width: 100%;
    display: flex;
}
.introduceMsg{
    flex: 1;
    /* text-align: center; */
}
.text_left{
    text-align: left;
}
.text_center{
    text-align: center;
}
.text_right{
    text-align: right;
}
</style>