<template><div class="container">


    <div class="tabs">
        <span class="tab" @click="toggleTab('inv')"><a>心理志愿团招募</a> </span>
        <span class="tab" @click="toggleTab('tab2')"><a>心理咨询师</a></span>
        <span class="tab" @click="toggleTab('tab3')"><a>心理督导师</a></span>
        <span class="tab" @click="toggleTab('tab4')"><a>培训教材</a></span>
    </div>
    <prince :is="currentTab" keep-alive></prince>
    <!-- 展示区 -->
  
</div>
</template>
<script>
import inv from './inviteMsg.vue';
import tab2 from './Tab2.vue';
import  tab3 from './Tab3.vue';
import tab4 from './Tab4.vue';

export default{
    name:'app',
    data(){
        return{
            currentTab:'inv',
          
        }
    },
    components:{
        inv,tab2,tab3,tab4
    },
    methods: {
        toggleTab:function(tab){
            this.currentTab = tab;
        }
    },
}
</script>
<style scoped >
.container{
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    
}

.tabs{
    width: 100%;
    height: 70px;
    display: flex;
    background-color:#536CFE ;
    box-sizing: border-box;
    padding: 10px 0 0 0 ;
}
.tabs span{
    flex: 1;
    text-align: center;
    line-height: 60px;
    font-weight: 700;
    color: #fff;
}
.tabs span:hover{
    background-color: #fff;
    color: #536CFE;
}


</style>