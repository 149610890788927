<template>
  <div class="company">
    <!--海报-->
    <div class="flex-box">
      <div class="banner" @click="scrollTo('scheme')">
        <img
          v-lazy="require('@/assets/company/banner.png')"
          class="banner-img"
        />
      </div>
    </div>
    <!--区域导航-->
    <div class="tabs" ref="tabs">
      <div class="contain">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          @click="scrollTo(tab.id)"
          class="tab"
        >
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--悬浮的区域导航-->
    <div class="tabs fixed" v-if="showTabHeader">
      <div class="contain">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          @click="scrollTo(tab.id)"
          :class="['tab', tab.hover ? 'hover' : '']"
        >
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--企业解决方案-->
    <div class="scheme" ref="scheme">
      <div class="title-box reveal">
        <div class="title1">康美心理·政企解决方案</div>
        <div class="title3">
          “人尽其才”关系着企业生产效率，“人事安全”关乎着企业生存和发展。
        </div>
        <div class="title3">
          通过系列职业规划测评、员工心理健康监测、通用人才选拔、能力测评、领导力测评等，为人才招聘、人员培训、人员安置、考核
        </div>
        <div class="title3">
          评价、提干晋升、团队建设、安全生产提供科学依据，降低企业风险，助力企业稳步发展。
        </div>
      </div>
      <div class="scheme-box reveal">
        <img
          v-lazy="require('@/assets/company/scheme-img.png')"
          class="scheme-img revealImg"
        />
        <div class="revealContent">
          <div>如何<span style="color: #f05b4f"> 选人、用人 </span>？</div>
          <div>如何<span style="color: #f05b4f"> 防范人事风险 </span>？</div>
          <div>如何<span style="color: #f05b4f">保障安全生产管理 </span>？</div>
        </div>
      </div>
      <div class="scheme-contain">
        <div class="box reveal1">
          <img
            v-lazy="require('@/assets/company/scheme-img1.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/company/scheme-logo1.png')"
                class="logo"
              />
              <div class="box-title">降低企业用人风险</div>
            </div>
            <div class="box-intro">
              知人知面不知心，社会压力日趋加大，对企业用工要求越来越严苛，有关劳动、人事纠纷案例越来越多，企业必须重视人事风险的防范。
            </div>
          </div>
        </div>
        <div class="box reveal2">
          <img
            v-lazy="require('@/assets/company/scheme-img2.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/company/scheme-logo2.png')"
                class="logo"
              />
              <div class="box-title">为企业人事管理<br />提供科学依据</div>
            </div>
            <div class="box-intro">
              为企业进行人才招聘、人员培训、人员安置、考核评价、提干晋升、团队建设提供科学依据。
            </div>
          </div>
        </div>
        <div class="box reveal3">
          <img
            v-lazy="require('@/assets/company/scheme-img3.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/company/scheme-logo3.png')"
                class="logo"
              />
              <div class="box-title">保障安全生产<br />为企业带来更高效益</div>
            </div>
            <div class="box-intro">
              促进员工心理健康、及时发现心理隐患、降低管理成本、提升组织文化、提高企业业绩，为企业带来更高的效益。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--多场景应用-->
    <div class="use" ref="use">
      <div class="title-box reveal">
        <div class="title1">康美心理测评·政企应用场景</div>
        <div class="title2">心理测评的需求，贯穿企业发展的各个环节</div>
      </div>
      <div class="use-box">
        <img
          v-lazy="require('@/assets/company/use1.png')"
          class="use-img revealImg"
        />
        <div class="use-content revealContent">
          <div>
            <div class="use-title">
              01-企业周期性员工心理安全监测
            </div>
            <div class="flex">
              <div class="use-shape"></div>
              <div class="use-intro">
                专业临床心理量表，排查员工个人心理隐患
              </div>
            </div>
            <div class="flex">
              <div class="use-shape"></div>
              <div class="use-intro">
                出具企业整体分析报告，心理危机分级预警
              </div>
            </div>
            <div class="flex">
              <div class="use-shape"></div>
              <div class="use-intro">
                心理危机干预体系，疏解员工压力提升效率
              </div>
            </div>
          </div>
          <div class="use-btn-box">
            <div class="use-btn" @click="gotoLogin">申请试用</div>
          </div>
        </div>
      </div>
      <div class="use-box" style="justify-content: flex-end">
        <div class="use-content revealContent">
          <div>
            <div class="use-title">02-企业人才招聘心理评估</div>
            <div class="flex">
              <div class="use-shape"></div>
              <div class="use-intro">
                人才甄选：鉴别应聘者的心理特质，人岗匹配更科学
              </div>
            </div>
            <div class="flex">
              <div class="use-shape"></div>
              <div class="use-intro">
                人员安置：预测安置人员与招聘岗位之间的契合度
              </div>
            </div>
            <div class="flex">
              <div class="use-shape"></div>
              <div class="use-intro">
                招聘导向：对以后的招聘提供指导，提升招聘效果
              </div>
            </div>
          </div>
          <div class="use-btn-box">
            <div class="use-btn" @click="gotoLogin">申请试用</div>
          </div>
        </div>
        <img
          v-lazy="require('@/assets/company/use2.png')"
          class="use-img revealImg"
          style="margin: 0 0 0 178px"
        />
      </div>
      <div class="use-box">
        <img
          v-lazy="require('@/assets/company/use3.png')"
          class="use-img revealImg"
        />
        <div class="use-content revealContent">
          <div>
            <div class="use-title">03-企业员工晋升评估筛选</div>
            <div class="flex">
              <div class="use-shape"></div>
              <div class="use-intro">
                职业规划测评、通用人才选拔、能力测评、领导力测评，对候选人进行全方面分析
              </div>
            </div>
            <div class="flex">
              <div class="use-shape"></div>
              <div class="use-intro">
                反馈员工的不足，为个人提升和进步提供导向
              </div>
            </div>
            <div class="flex">
              <div class="use-shape"></div>
              <div class="use-intro">
                团队建设：识别不同成员间的异同，有助于更合理地选配人员，调整及优化团队配置
              </div>
            </div>
          </div>
          <div class="use-btn-box">
            <div class="use-btn" @click="gotoLogin">申请试用</div>
          </div>
        </div>
      </div>
    </div>
    <!--体验测评-->
    <div class="eval" ref="eval">
      <div class="eval-box reveal">
        <img
          v-lazy="require('@/assets/company/eval.png')"
          class="eval-img revealImg"
        />
      </div>
    </div>
    <!--权威测评量表-->
    <div class="projects" ref="projects">
      <div class="title-box reveal">
        <div class="title1">康美心理·综合性政企心理测评服务</div>
        <div class="title2">
          做更专业的量表测评，看更科学的数据分析，排查人事安全隐患
        </div>
      </div>
      <div class="project-contain">
        <div class="box reveal1">
          <img
            v-lazy="require('@/assets/company/project1.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/company/project-logo1.png')"
                class="logo1"
              />
              <div class="box-title">
                国际权威量表，专业临床应用
              </div>
            </div>
            <div class="box-intro">
              采用国际权威通用的量表，常模本土化，结合专家团队在国内的大量临床实践修订，多个专业心理安全监测量表，为企业评测员工身心健康作全方位指标精准监测分析，也是专业心理咨询机构、三甲医院常用的诊断标准。
            </div>
          </div>
        </div>
        <div class="box reveal2">
          <img
            v-lazy="require('@/assets/company/project2.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/company/project-logo2.png')"
                class="logo2"
              />
              <div class="box-title">
                数据可视化，享全年监测服务
              </div>
            </div>
            <div class="box-intro">
              随时随地发起监测，及时排查人事安全隐患和风险预警。全年实时关注心理健康状况，生成全年动态心理档案。智能监测提醒，根据每次结果科学定制下次测评时间。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--评测报告解读-->
    <div class="report" ref="report">
      <div class="title-box reveal">
        <div class="title1">面向政企管理人员输出整体分析报告</div>
        <div class="title2">
          心理专家团队出具整体综合分析报告，精准分析员工身心健康状况，排查人事安全隐患
        </div>
      </div>
      <div class="report-contain">
        <div class="box reveal1">
          <div class="box-title title-bg1">
            <div style="background: #fafbff">身体健康情况评估</div>
          </div>
          <div class="box-intro">
            12大方面定位你的身体各项状况，精细化分析症状表现，快速筛查潜在健康风险。
          </div>
          <img
            v-lazy="require('@/assets/company/report-img1.png')"
            class="box-img1"
          />
        </div>
        <div class="box reveal2">
          <div class="box-title title-bg2">
            <div style="background: #fafbff">心理健康情况评估</div>
          </div>
          <div class="box-intro">
            从6大方面评估你的精神心理状态，详细解析对应的心理倾向，准确筛查潜在精神层面风险。
          </div>
          <img
            v-lazy="require('@/assets/company/report-img2.png')"
            class="box-img2"
          />
        </div>
        <div class="box reveal3">
          <div class="box-title title-bg3">
            <div style="background: #fafbff">职业倾向评估</div>
          </div>
          <div class="box-intro">
            根据性格和职业之间的联系，从12大方面，衡量和描述员工在获取信息、作出决策、对待生活等方面的心理活动规律和性格类型。
          </div>
          <img
            v-lazy="require('@/assets/company/report-img3.png')"
            class="box-img3"
          />
        </div>
        <div class="box reveal4">
          <div class="title4">更多专业报告<br />持续输出</div>
          <img
            v-lazy="require('@/assets/company/report-img4.png')"
            class="box-img4"
          />
        </div>
      </div>
    </div>
    <!-- 新增tab  9.19 -->
    <div style="margin-top:80px;">
      <Tab1></Tab1>
</div>



    <!-- 申请试用 -->
    <div class="test" ref="test">
      <div class="title-box reveal">
        <div class="title1">申请免费试用</div>
        <div class="title2">填写信息，立即开通体验</div>
      </div>
      <img
          v-lazy="require('@/assets/company/Group 502.png')"
          style="height:600px;width:1200px"
          class="revealImg"
          @click="gotoLogin"
        />
    </div>
    <!-- <div style="font-size:35px; width:950px; height:50px; text-align:center; font-weight:bold;" >浙ICP备2022026803号</div> -->
  </div>
</template>

<script>
  import  Tab1 from '../../components/tab1.vue';



export default {
    name: "Company",
    data() {
        return {
            tabs: [
                {
                    name: "企业解决方案",
                    id: "scheme",
                    hover: false,
                },
                {
                    name: "多场景应用",
                    id: "use",
                    hover: false,
                },
                {
                    name: "体验测评",
                    id: "eval",
                    hover: false,
                },
                {
                    name: "权威测评量表",
                    id: "projects",
                    hover: false,
                },
                {
                    name: "测评报告解读",
                    id: "report",
                    hover: false,
                },
                {
                    name: "申请试用",
                    id: "test",
                    hover: false,
                },
            ],
                  showTabHeader: false,
            scrollReveal: new scrollReveal(),
        };
    },
    mounted() {
        this.getReveal();
        window.addEventListener("scroll", this.handleScroll, true);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll, true);
    },
    methods: {
        handleScroll: function () {
            this.$nextTick(() => {
                let top = this.$refs.tabs.getBoundingClientRect().top;
                this.showTabHeader = top <= 0 ? true : false;
                if (top <= 0) {
                    this.tabs.forEach((tab) => {
                        let tabData = this.$refs[tab.id].getBoundingClientRect();
                        if (Math.floor(tabData.top) <= 0 &&
                            Math.floor(tabData.height + tabData.top) > 0) {
                            tab["hover"] = true;
                        }
                        else {
                            tab["hover"] = false;
                        }
                    });
                }
            });
        },
        scrollTo: function (name) {
            this.$nextTick(() => {
                this.$refs[name].scrollIntoView({ block: "start", behavior: "smooth" });
            });
        },
        gotoLogin() {
            this.$router.push("/login");
        },
        getReveal() {
            let animation = {
                duration: 1000,
                reset: false,
                mobile: false,
                opacity: 0,
                easing: "ease-in-out",
                enter: "bottom",
                distance: "40px",
            };
            let setReveal = (className, delay) => {
                let revealAnimation = JSON.parse(JSON.stringify(animation));
                revealAnimation["delay"] = delay;
                this.scrollReveal.reveal(className, revealAnimation);
            };
            setReveal(".reveal", 0);
            setReveal(".revealImg", 500);
            setReveal(".revealContent", 800);
            setReveal(".reveal1", 200);
            setReveal(".reveal2", 500);
            setReveal(".reveal3", 800);
            setReveal(".reveal4", 1100);
        },
    },
    components: { Tab1}
};
</script>
<style scoped lang="scss">
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.company {
  width: 100%;
  background: #fff;

  .title-box {
    @include flex($direction: column, $justify: center);
    text-align: center;
    color: $title-color;
    .title1 {
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .title2 {
      font-size: 24px;
      line-height: 33px;
    }
    .title3 {
      font-size: 16px;
      line-height: 30px;
    }
  }

  .banner {
    @include flex($direction: column);
    width: 100%;
    height: 500px;
    overflow: hidden;
    background-image: url("~@/assets/company/banner-bg.jpg");
    background-size: 100% 500px;
    background-repeat: no-repeat;
    background-color: #fff;

    .banner-img {
      width: 1920px;
      height: 500px;
    }
  }

  .tabs {
    width: 100%;
    height: 60px;
    background: #f0f1f7;
    @include flex($justify: center);
    .contain {
      width: $width;
      height: 100%;
      @include flex($justify: space-around);
      .tab {
        font-size: 16px;
        color: $title-color;
      }
    }
  }

  .fixed {
    background-color: #f5f6f8;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    height: 60px;
    .tab {
      position: relative;
      padding: 20px 0;
    }
    .tab::after {
      position: absolute;
      content: "";
      bottom: 1px;
      left: 50%;
      width: 0;
      height: 3px;
      border-radius: 30px;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background-color: $color;
      -webkit-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }
    .tab.hover {
      color: $color;
    }
    .tab:hover {
      color: $color;
    }
    .tab:hover::after {
      width: 100%;
    }
  }

  .scheme {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 40px;
    }

    .scheme-box {
      width: $width;
      padding: 40px 80px;
      background: #fafbff;
      @include flex($justify: center);
      font-size: 36px;
      color: $title-color;
      line-height: 60px;
      margin-bottom: 40px;
      .scheme-img {
        flex-shrink: 0;
        width: 520px;
        height: 308px;
        margin-right: 60px;
      }
    }
    .scheme-box:hover {
      box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
    }
    .scheme-contain {
      width: $width;
      @include flex($justify: space-between);
      .box {
        width: 390px;
        height: 463px;
        border-radius: 4px;
        overflow: hidden;
        background: #fafbff;
        .box-img {
          width: 390px;
          height: 220px;
        }
        .box-main {
          padding: 30px 40px;
          .logo {
            width: 60px;
            height: 60px;
            margin-right: 20px;
            flex-shrink: 0;
          }
          .flex {
            @include flex;
          }
          .box-title {
            color: $title-color;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
          }
          .box-intro {
            color: $intro-color;
            font-size: 16px;
            line-height: 24px;
            margin-top: 24px;
          }
        }
      }
      .box:hover {
        box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
      }
    }
  }

  .use {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 40px;
    }
    .use-box {
      @include flex;
      background: #fafbfe;
      width: $width;
      height: 350px;
      padding: 40px;
      .use-img {
        width: 480px;
        height: 270px;
        margin-right: 80px;
      }
      .use-content {
        height: 100%;
        @include flex($direction: column, $justify: space-between);
        .use-title {
          font-size: 24px;
          color: $title-color;
          font-weight: bold;
          margin-bottom: 30px;
        }
        .use-intro {
          font-size: 16px;
          color: $intro-color;
          max-width: 460px;
          line-height: 24px;
        }
        .use-shape {
          width: 10px;
          height: 10px;
          background: #ffffff;
          border: 2px solid #536cfe;
          border-radius: 50%;
          margin-right: 10px;
          flex-shrink: 0;
          margin-top: 6px;
        }
        .flex {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 20px;
        }
        .use-btn-box {
          width: 100%;
          @include flex;
          .use-btn {
            width: 120px;
            height: 40px;
            line-height: 40px;
            border: 1px solid $color;
            color: $color;
            text-align: center;
            font-size: 16px;
            transition: all 0.5s;
            border-radius: 20px;
          }
          .use-btn:hover {
            background: $color;
            color: #fff;
            transform: translateY(-4px);
          }
        }
      }
    }
    .use-box:not(:last-of-type) {
      margin-bottom: 30px;
    }
    .use-box:hover {
      box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
    }
  }

  .eval {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .eval-box {
      @include flex($justify: center);
      width: 100%;
      height: 600px;
      background: #fafbff;
      overflow: hidden;

      .eval-img {
        width: 1920px;
        height: 600px;
      }
    }
  }

  .projects {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 40px;
    }
    .project-contain {
      width: $width;
      @include flex($justify: space-between);
      .box {
        width: 590px;
        height: 535px;
        border-radius: 4px;
        overflow: hidden;
        background: #fafbff;
        .box-img {
          width: 590px;
          height: 332px;
        }
        .box-main {
          padding: 30px 40px;
          .logo1 {
            width: 26px;
            height: 32px;
            margin-right: 24px;
            flex-shrink: 0;
          }
          .logo2 {
            width: 30px;
            height: 30px;
            margin-right: 24px;
            flex-shrink: 0;
          }
          .flex {
            @include flex;
          }
          .box-title {
            color: $title-color;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
          }
          .box-intro {
            color: $intro-color;
            font-size: 16px;
            line-height: 24px;
            margin-top: 20px;
          }
        }
      }
      .box:hover {
        box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
      }
    }
  }

  .report {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 60px;
    }
    .report-contain {
      width: $width;
      @include flex($justify: space-between);
      .box {
        @include flex($direction: column, $justify: center);
        width: 288px;
        height: 448px;
        border-radius: 4px;
        background: #fafbff;
        padding: 20px 24px;
        .box-title {
          @include flex($direction: column, $justify: center);
          width: 100%;
          height: 156px !important;
          font-size: 24px;
          color: #596df8;
          font-weight: 500;
          background-color: #fafbff;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 76px 156px !important;
        }
        .title-bg1 {
          background-image: url("~@/assets/company/title-bg1.png");
        }
        .title-bg2 {
          background-image: url("~@/assets/company/title-bg2.png");
        }
        .title-bg3 {
          background-image: url("~@/assets/company/title-bg3.png");
        }
        .title4 {
          color: #596df8;
          font-weight: 500;
          background-color: #fafbff;
          padding: 82px 0 70px;
          line-height: 33px;
          font-size: 24px;
          text-align: center;
        }
        .box-intro {
          font-size: 16px;
          color: $intro-color;
          line-height: 24px;
        }
        .box-img1 {
          width: 178px;
          height: 178px;
        }
        .box-img2 {
          width: 151px;
          height: 151px;
          margin-top: 20px;
        }
        .box-img3 {
          width: 161px;
          height: 161px;
          margin-top: -10px;
        }
        .box-img4 {
          width: 189px;
          height: 189px;
        }
      }
      .box:hover {
        box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
      }
    }
  }

  .test {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 40px;
    }
    .test-img {
      width: 1200px;
      height: 600px;
    }

  }
}
</style>
